import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataResolverService } from './services/data-resolver.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  //  {
  //   path: 'business-details/:id',
  //   // resolve:{
  //   //   businessDetail:DataResolverService
  //   // },
  //   loadChildren: () => import('./business-details/business-details.module').then( m => m.BusinessDetailsPageModule)
  // },
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  {
    path: 'reviews-leaderboard',
    loadChildren: () =>
      import('./reviews-leaderboard/reviews-leaderboard.module').then(
        (m) => m.ReviewsLeaderboardPageModule
      ),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./blogs/blogs.module').then((m) => m.BlogsPageModule),
  },
  {
    path: 'blog/:id/:title',
    resolve: {
      item: DataResolverService,
    },
    loadChildren: () =>
      import('./blogs/blogs.module').then((m) => m.BlogsPageModule),
  },
  {
    path: ':item/:name',
    resolve: {
      item: DataResolverService,
    },
    loadChildren: () =>
      import('./business-details/business-details.module').then(
        (m) => m.BusinessDetailsPageModule
      ),
  },
  // {
  //   path: 'business-details/:name',
  //   // resolve:{
  //   //   item:DataResolverService
  //   // },
  //   loadChildren: () => import('./business-details/business-details.module').then( m => m.BusinessDetailsPageModule)
  // },

  // {
  //   path: 'business-details',
  //   loadChildren: () => import('./business-details/business-details.module').then( m => m.BusinessDetailsPageModule)
  // },
  {
    path: 'search-business',
    loadChildren: () =>
      import('./search-business/search-business.module').then(
        (m) => m.SearchBusinessPageModule
      ),
  },
  {
    path: 'reviews',
    loadChildren: () =>
      import('./reviews/reviews.module').then((m) => m.ReviewsPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./cart/cart.module').then((m) => m.CartPageModule),
  },
  {
    path: 'add-reviews',
    loadChildren: () =>
      import('./add-reviews/add-reviews.module').then(
        (m) => m.AddReviewsPageModule
      ),
  },
  {
    path: 'order-settings',
    loadChildren: () =>
      import('./order-settings/order-settings.module').then(
        (m) => m.OrderSettingsPageModule
      ),
  },
  {
    path: 'show-user-reviews',
    loadChildren: () =>
      import('./show-user-reviews/show-user-reviews.module').then(
        (m) => m.ShowUserReviewsPageModule
      ),
  },
  {
    path: 'item-details',
    loadChildren: () =>
      import('./item-details/item-details.module').then(
        (m) => m.ItemDetailsPageModule
      ),
  },
  {
    path: 'photo-viewer',
    loadChildren: () =>
      import('./photo-viewer/photo-viewer.module').then(
        (m) => m.PhotoViewerPageModule
      ),
  },
  {
    path: 'menu',
    loadChildren: () =>
      import('./menu/menu.module').then((m) => m.MenuPageModule),
  },
  {
    path: 'proceed',
    loadChildren: () =>
      import('./proceed/proceed.module').then((m) => m.ProceedPageModule),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: 'thank-you',
    loadChildren: () =>
      import('./thank-you/thank-you.module').then((m) => m.ThankYouPageModule),
  },
  {
    path: 'user-reviews',
    loadChildren: () =>
      import('./user-reviews/user-reviews.module').then(
        (m) => m.UserReviewsPageModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsPageModule),
  },
  {
    path: 'gift-cards',
    loadChildren: () =>
      import('./gift-cards/gift-cards.module').then(
        (m) => m.GiftCardsPageModule
      ),
  },
  {
    path: 'my-orders',
    loadChildren: () =>
      import('./my-orders/my-orders.module').then((m) => m.MyOrdersPageModule),
  },
  {
    path: 'add-new-business',
    loadChildren: () =>
      import('./add-new-business/add-new-business.module').then(
        (m) => m.AddNewBusinessPageModule
      ),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./about-us/about-us.module').then((m) => m.AboutUsPageModule),
  },

  {
    path: 'addresses',
    loadChildren: () =>
      import('./addresses/addresses.module').then((m) => m.AddressesPageModule),
  },
  {
    path: 'payment-methods',
    loadChildren: () =>
      import('./payment-methods/payment-methods.module').then(
        (m) => m.PaymentMethodsPageModule
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./contact-us/contact-us.module').then(
        (m) => m.ContactUsPageModule
      ),
  },
  {
    path: 'terms-privacy',
    loadChildren: () =>
      import('./terms-privacy/terms-privacy.module').then(
        (m) => m.TermsPrivacyPageModule
      ),
  },
  {
    path: 'information',
    loadChildren: () =>
      import('./information/information.module').then(
        (m) => m.InformationPageModule
      ),
  },
  {
    path: 'order-details',
    loadChildren: () =>
      import('./order-details/order-details.module').then(
        (m) => m.OrderDetailsPageModule
      ),
  },
  {
    path: 'buy-giftcard',
    loadChildren: () =>
      import('./buy-giftcard/buy-giftcard.module').then(
        (m) => m.BuyGiftcardPageModule
      ),
  },
  {
    path: 'giftcard-banner',
    loadChildren: () =>
      import('./giftcard-banner/giftcard-banner.module').then(
        (m) => m.GiftcardBannerPageModule
      ),
  },
  {
    path: 'accept-terms',
    loadChildren: () =>
      import('./accept-terms/accept-terms.module').then(
        (m) => m.AcceptTermsPageModule
      ),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'phoneconfirm',
    loadChildren: () =>
      import('./ask-phone/ask-phone.module').then((m) => m.AskPhonePageModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./landing/landing.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'open-review',
    loadChildren: () =>
      import('./open-review/open-review.module').then(
        (m) => m.OpenReviewPageModule
      ),
  },
  {
    path: 'user-location',
    loadChildren: () =>
      import('./user-location/user-location.module').then(
        (m) => m.UserLocationPageModule
      ),
  },
  {
    path: 'doordash-error',
    loadChildren: () =>
      import('./doordash-error/doordash-error.module').then(
        (m) => m.DoordashErrorPageModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'follow-us',
    loadChildren: () => import('./follow-us/follow-us.module').then( m => m.FollowUsPageModule)
  },
  {
    path: 'my-businesses',
    loadChildren: () => import('./my-businesses/my-businesses.module').then( m => m.MyBusinessesPageModule)
  },
  {
    path: 'tip-modal',
    loadChildren: () => import('./tip-modal/tip-modal.module').then( m => m.TipModalPageModule)
  },
  {
    path: 'blogs',
    loadChildren: () => import('./blog-list/blog-list.module').then( m => m.BlogListPageModule)
  },
  {
    path: 'leaderboard-filters',
    loadChildren: () => import('./leaderboard-filters/leaderboard-filters.module').then( m => m.LeaderboardFiltersPageModule)
  },
  {
    path: 'perks',
    loadChildren: () => import('./perks/perks.module').then( m => m.PerksPageModule)
  },

  {
    path: 'events',
    loadChildren: () => import('./survey/survey.module').then( m => m.SurveyPageModule)
  },
  {
    path: 'event',
   
    loadChildren: () => import('./servey-details/servey-details.module').then( m => m.ServeyDetailsPageModule)
  },
  {
    path: 'event/:evname/:id',
    resolve: {
      item: DataResolverService,
    },
    loadChildren: () => import('./servey-details/servey-details.module').then( m => m.ServeyDetailsPageModule)
  },
  {
    path: '404',
    loadChildren: () =>
      import('./not-found/not-found.module').then((m) => m.NotFoundPageModule),
  },
  { path: '**', redirectTo: '/404' },
  {
    path: 'survey-alert',
    loadChildren: () => import('./survey-alert/survey-alert.module').then( m => m.SurveyAlertPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },
 

  

 
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
