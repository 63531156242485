import { Component, Input, OnInit } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Component({
  selector: 'business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss'],
})
export class BusinessCardComponent implements OnInit {
  @Input('name') name;
  @Input('logo') logo;
  @Input('ratings') ratings
  @Input('totalReviews') totalReviews;
  @Input('deliveryTime') deliveryTime;
  @Input('deliveryFee') deliveryFee;
  @Input('cuisineName') cuisineName;
  @Input('pickup') pickup;
  @Input('delivery') delivery;
  @Input('distanceValue') distanceValue;
  @Input('pickupTime') pickupTime;
  @Input('address') address;
  @Input('featured') featured;
  @Input('phone') phone;
  @Input('isopen') isopen;
  @Input('order') order;

  constructor(public globals: GlobalVariable) {

  }
ionViewWillEnter(){
  this.ratings=Number.parseFloat(this.ratings).toFixed(1);
}
  ngOnInit() {}
  floated(x) {
    return Number.parseFloat(x).toFixed(1);
  }
  onRate(e) {
    // console.log(e);
  }
  numberChange(phone) {
    if (phone) {
      if (phone.includes('+', 0)) {
        // console.log('include +');
        phone = phone.slice(2);
        // console.log(phone)
      }
    }
    // console.log('here')
    // this.formatedPhone = String(phone;
    var cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return (
        '(' + match[1] + ') ' + (match[2] ? match[2] + '-' : '') + match[3]
      );
      // console.log(this.formatedPhone)
    }
    // var match = cleaned.match( /^(\d{3})(\d{3})(\d{4})$/)
    // if (match) {

    //     console.log('match')
    //   this.formatedPhone= '(' + match[1] + ') ' + match[2] + '-' + match[3]
    // }
    return null;
    // var clean = this.formatedPhone.replace(/[^\d]/g, '')
    // console.log(clean)
    // this.formatedPhone = clean.replace(/[0-9]{3}-[0-9]{3}-[0-9]{4}/g,'');
    // console.log(this.formatedPhone)
  }
}
