import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import {
  AlertController,
  ModalController,
  NavController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { GlobalVariable } from 'src/app/global';
import { ServerService } from 'src/app/providers/server.service';


declare  var google;
@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss'],
})
export class NewHeaderComponent implements OnInit {
  temp: boolean = false;
  @Input('ismodal')ismodal
  showMenu=false;
  addressSearch: boolean = false;
  geo: any;
  email:any;
  autocompleteItems: any;
  autocomplete: any;
  service = new google.maps.places.AutocompleteService();
  items: Object[] = [];
  isLoggedIn:any
  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public globals: GlobalVariable,
    public server: ServerService,
    public toastCtrl: ToastController,
    public router: Router,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public zone: NgZone,
    private socialShare:SocialSharing,
    private geolocation:Geolocation
  ) {
    this.autocompleteItems = [];
    this.autocomplete = {
      query: '',
    };
    this.isLoggedIn = localStorage.getItem('isLogin');
   }

  ngOnInit() {}
  openMenuButtons(){
    this.temp=false;
    this.addressSearch=false;
    if(this.showMenu==true){
      this.showMenu=false
    }
    else{
      this.showMenu=true
    }
  }
  dismissAllModals() {
    this.modalCtrl.dismiss();
  }
  async cartPage() {
    this.showMenu=false;
    this.modalCtrl.dismiss();
   
    if (this.globals.Product.length == 0) {
      let alert = await this.alertCtrl.create({
        header: 'Empty cart',
        message: 'Hungry? order some food now',
        buttons: ['Okay'],
      });
      alert.present();
    } else {
    this.showMenu=false;
      this.router.navigateByUrl('cart', {})
        
    }
  }
  login() {
    this.navCtrl.navigateForward('/login');
    this.modalCtrl.dismiss();
  }
  editAddress() {
  
    if(this.addressSearch==true){
this.addressSearch=false
    }
    else{
      this.addressSearch=true
    }
  }
  dismiss() {
    this.temp = false;
    this.autocompleteItems = [];
  }
  cancelAddressSearch() {
    this.addressSearch = false;
  }
  chooseItem(item: any) {
    if (item == undefined || item == null) {
      this.globals.presentToast('Invalid address!');
      return;
    }
    this.autocomplete.query = item;
    this.geo = item;
    this.globals.address = this.autocomplete.query;
    this.addressSearch = false;
    this.autocompleteItems = [];
    // this.globals.dataNotLoaded=true;
    // this.resList=[];
    // this.hasData=true;
    // this.offset=0;
    // this.limit=20;
    // this.list();
    this.dismiss();
    this.geoCode(this.geo);
    console.log('item', item);
    console.log('geo', this.geo);
  }
  geoCode(address: any) {
    // let that = this;
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      this.globals.mycoordinates =
        results[0].geometry.location.lat() +
        ',' +
        results[0].geometry.location.lng();
      // new added
  
    });
  }
  updateSearch() {
    console.log('search is on');
    console.log(this.autocompleteItems);
    console.log('length', this.autocomplete.query.length);

    if (this.autocomplete.query == '') {
      console.log('if run');
      this.autocompleteItems = [];
      this.temp = false;
      return;
    } else if (this.autocomplete.query.length >= 3) {
      console.log('else if run');
      this.temp = true;
      let me = this;
      this.service.getPlacePredictions(
        {
          input: this.autocomplete.query,
          componentRestrictions: {
            country: ["us", "ca"],
          },
        },
        (predictions, status) => {
          me.autocompleteItems = [];

          me.zone.run(() => {
            if (predictions != null) {
              predictions.forEach((prediction) => {
                me.autocompleteItems.push(prediction.description);
                console.log(me.autocompleteItems);
              });
            }
          });
        }
      );
    } else {
      console.log('else run');
    }
  }
  getLocation() {
    this.globals.presentToast('Getting location...');
    this.geolocation.getCurrentPosition().then(
      (position) => {
        this.addressSearch = false;
        this.globals.mycoordinates =
          position.coords.latitude + ',' + position.coords.longitude;
        this.reverseGeoCoding();
      },
      async (err) => {
        let alert = await this.alertCtrl.create({
          header: this.globals.locationAlert_title,
          subHeader: this.globals.locationAlert_text,
          buttons: [
            {
              text: 'Cancel',
              handler: (data) => {
            
                // console.log('Cancel clicked');
              },
            },
            {
              text: 'OK',
              handler: (data) => {
           
                // console.log('Ok clicked');
              },
            },
          ],
        });

        alert.present();
        // console.log(err);
      }
    );
  }
  reverseGeoCoding() {
    let response = this.server.getAddress(this.globals.mycoordinates);
    var myadress = '';
    response.subscribe(
      (data) => {
        console.log('data from adrress api ', data);
        myadress = data.address;
        this.globals.address = myadress;
     
      },
      (error) => {
        this.globals.presentToast('Please Turn on your location.');
      }
    );
  }
  async referFriend() {
    let currentUrl = window.location.hostname;
    // console.log(currentUrl)
    //change Weburl if changing domain.
    if (this.globals.webURL == currentUrl) {
      const alert = await this.alertCtrl.create({
        message: 'Coming soon.',
        buttons: ['Okay'],
        cssClass: 'customAlert',
      });
      await alert.present();
    } else {
      let Title = 'The Halal Guide';
      let msg =
        Title +
        ' is one of the best halal food ordering apps. Order now';
      // this.socialShare.canShareVia
      // net.thehalalguide.app://thehalalguide.com
      let B_link = 'http://halalguide.app.link/jYA2rkA33kb';

      // let simpleAppURL='http://halalguide.app.link/jYA2rkA33kb';
      // if(this.platform.is('browser') || this.platform.is('mobileweb')){

      // }
      this.socialShare
        .share(msg, Title, null, B_link)
        .then((res) => {
          console.log('share via referFriend', res);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    //  if(this.platform.is('android') || this.platform.is('ios')){

    //   else{

    //   }
  }
  async perks() {
    const alert = await this.alertCtrl.create({
      message: 'Coming soon.',
      buttons: ['Ok'],
    });
    await alert.present();
  }
  openAddressesPage() {
    this.showMenu=false;
    this.dismissAllModals();
   
    this.navCtrl.navigateForward('addresses');
  }
  openPaymentsPage() {
    this.showMenu=false;
    this.dismissAllModals();
 
    this.navCtrl.navigateForward('payment-methods');
    // console.log('')
  }
  openFavPage() {
    this.globals.activeCuisineList = 'favorites';
    this.showMenu=false;
    this.dismissAllModals();
  
    this.router.navigate(['/search-business']);
  }

  openProfile() {
    this.showMenu=false;
    this.dismissAllModals();

    this.navCtrl.navigateForward('settings');
  }
  
  followUs(){
this.showMenu=false;
this.dismissAllModals();

    this.navCtrl.navigateForward('follow-us');
  }
 
  goToGifts() {
    // this.showMenu=false;
    this.dismissAllModals();
    this.showMenu=false;
    this.navCtrl.navigateForward('gift-cards');
  }
  openHistory() {
    this.showMenu=false;
    this.dismissAllModals();

    this.navCtrl.navigateForward('my-orders');
  }
  async leaderboard() {
    this.showMenu=false;
    this.dismissAllModals();

    // let modal = await this.modalCtrl.create({
    //   component: ReviewsLeaderboardPage,
    //   cssClass:'review_leaderBoard',
    // });
    // await modal.present();
    // this.navCtrl.push('ReviewsLeaderboardPage');
    this.navCtrl.navigateForward('reviews-leaderboard')
  }
  helpPage() {
    this.showMenu=false;
    this.dismissAllModals();

    this.navCtrl.navigateForward('about-us');
  }
  blogsPage() {
    this.showMenu=false;
    this.dismissAllModals();
  
    this.navCtrl.navigateForward('blogs');
  }
  close(){
    this.modalCtrl.dismiss()
  }
}
