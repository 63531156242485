import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessCardComponent } from './business-card/business-card.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IonicRatingModule } from 'ionic-rating';
import { RatingModule } from 'ng-starrating';
import { MyCartComponent } from './my-cart/my-cart.component';
import { IonicModule } from '@ionic/angular';
import { NewHeaderComponent } from './new-header/new-header.component';
import { FormsModule } from '@angular/forms';

// import { StarRatingModule } from 'ionic3-star-rating';
@NgModule({
  declarations: [BusinessCardComponent, MyCartComponent,NewHeaderComponent],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    IonicRatingModule,
    RatingModule,
    IonicModule,
    FormsModule,
    // StarRatingModule,
  ],
  exports: [BusinessCardComponent, MyCartComponent,NewHeaderComponent],
})
export class ComponentsModule {}
